@import "src/@fuse/scss/fuse";

app-root,
inbrit-auth-layout,
app-admin-layout {
  position: relative;
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.inbrit-login {
  #login {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url("/assets/images/backgrounds/f_office.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
    #login-intro {
      padding: 128px;
      flex: 1 1 0%;
      box-sizing: border-box;

      @include media-breakpoint("sm") {
        padding: 128px 64px;
      }

      .logo {
        width: 128px;
        margin-bottom: 32px;
      }

      .title {
        font-size: 42px;
        font-weight: 600;
        line-height: 1;
        color: white;
      }

      .description {
        padding-top: 16px;
        font-size: 14px;
        max-width: 600px;
        color: white;
        margin-bottom: 10px;
      }
      .description-icon {
        color: white;
        font-size: 4.5rem;
        margin-right: 30px;
      }
    }

    #login-form-wrapper {
      width: 400px;
      min-width: 400px;
      max-width: 400px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      @include mat-elevation(16);

      @include media-breakpoint("sm") {
        width: 360px;
        min-width: 360px;
        max-width: 360px;
      }

      @include media-breakpoint("xs") {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }

      #login-form {
        padding: 50px 48px 100px 48px;

        background-color: white;
        @include media-breakpoint("xs") {
          text-align: center;
          padding: 24px;
          padding-bottom: 100px !important;
        }

        .logo {
          width: 128px;
          margin: 32px auto;
        }

        .title {
          font-size: 21px;
          text-align: center;
        }

        .description {
          padding-top: 8px;
        }

        form {
          width: 100%;
          padding-top: 32px;

          mat-form-field {
            width: 100%;

            /*@include media-breakpoint('xs') {
                            width: 80%;
                        }*/
          }

          mat-checkbox {
            margin: 0;
          }

          .remember-forgot-password {
            font-size: 13px;
            margin-top: 8px;

            .remember-me {
              margin-bottom: 16px;
            }

            .forgot-password {
              font-size: 13px;
              font-weight: 600;
              margin-bottom: 16px;
            }
          }

          .submit-button {
            width: 100%;
            margin: 16px auto;
            display: block;

            @include media-breakpoint("xs") {
              width: 80%;
            }
          }
        }

        .separator {
          font-size: 15px;
          font-weight: 600;
          margin: 24px auto;
          position: relative;
          overflow: hidden;
          width: 100px;
          text-align: center;

          .text {
            display: inline-flex;
            position: relative;
            padding: 0 8px;
            z-index: 9999;

            &:before,
            &:after {
              content: "";
              display: block;
              width: 30px;
              position: absolute;
              top: 10px;
              border-top: 1px solid;
            }

            &:before {
              right: 100%;
            }

            &:after {
              left: 100%;
            }
          }
        }

        button {
          &.google,
          &.facebook {
            width: 70%;
            text-transform: none;
            color: #ffffff;
            font-size: 13px;

            @include media-breakpoint("xs") {
              width: 60%;
            }

            mat-icon {
              color: #ffffff;
              margin: 0 8px 0 0;
            }
          }

          &.google {
            background-color: #d73d32;
            margin-bottom: 8px;
          }

          &.facebook {
            background-color: rgb(63, 92, 154);
          }
        }

        .register {
          margin: 32px auto 24px auto;
          width: 250px;
          font-weight: 600;

          .text {
            margin-right: 8px;
          }

          .link {
          }
        }
      }
    }
  }
}

.page-layout {
  // overflow: scroll;
  // height: 87vh;
  .content {
    // padding-right: 24px;
    // padding-left: 24px;
  }
  .card {
  }
}

.user-registration {
  width: 100%;
  background: url("/assets/images/backgrounds/f_office.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  .content,
  .center {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .top-bg,
  .header-bar {
    display: none !important;
  }
}

.forgot-password,
.reset-password {
  width: 100%;
  padding-top: 40px;
  background: url("/assets/images/backgrounds/f_office.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 700px;
  .logo {
    width: 128px;
    margin: 32px auto;
  }
  .title {
    font-size: 14px;
    margin: 16px 0 32px 0;
    text-align: center;
  }
  form {
    width: 100%;
    text-align: left;
    .submit-button {
      width: 220px;
      margin: 16px auto;
      display: block;
      @include media-breakpoint("xs") {
        width: 90%;
      }
    }
  }
  a {
    font-size: 14px;
  }
}

.h1,
.h2,
.h3 {
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  margin-top: 0px;
}

.mat-input {
  padding: 5px;
  border-bottom: 1px solid #e2e2e2;
}

.table,
.mat-table {
  width: 100%;
  th {
    font-size: 1.35rem !important;
    color: black;
  }
  td {
    .dropdown-menu .dropdown-item {
      font-size: 1.5rem;
      cursor: pointer;
      i {
        margin-right: 10px;
      }
    }
    .dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu a:hover,
    .dropdown-menu a:focus,
    .dropdown-menu a:active {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.04);
      color: #353535;
    }
    .nav-link {
      display: inline;
    }
  }
}

.radio-group {
  display: flex;
  .mat-radio-button {
    margin: 5px;
  }
}

.card {
  font-size: 1.25rem; 
  margin-top: 15px;
  .filter-button-group {
    padding-top: 5px;
  }
  .no-data-warning {    
    padding: 10px;
    border-radius: 8px;
    font-size: 15px;
  }
}



.price-tables {
  

  // @include media-breakpoint("xs") {
  //   margin-top: -128px;
  // }
  .price-table {
    margin: 12px;
    width: 100%;
    .logo {
      width: 100%;
    }
    .logo,
    .info {
      padding: 10px;
    }
    mat-divider {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .table {
      text-align: center;
    }
  }
}

 

.filter-popup {
  position: absolute !important;
  top: 120px;
  right: 150px;
}

.header-bar {
  // height: 80px;
  // min-height: 80px;
  // max-height: 100px;
  .search-wrapper {
    width: 100%;
    max-width: 280px;
    border-radius: 28px;
    overflow: hidden;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background: #fff;
    .search {
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding: 0 18px;
      mat-icon {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
  i {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.main-panel .navbar {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  z-index: 4;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  .nav-link .material-icons {
    font-size: 2.75rem !important;
  }

  .dropdown-menu .dropdown-item {
    font-size: 1.5rem;
    cursor: pointer;
    i {
      margin-right: 10px;
    }
  }
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu a:hover,
  .dropdown-menu a:focus,
  .dropdown-menu a:active {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.04);
    color: #353535;
  }
  .navbar-brand {
    font-size: 1.8rem;
    text-transform: uppercase;
    line-height: 40px;
  }
  .mat-button-wrapper {
    line-height: inherit;
  }
  .flag {
    margin-top: 10px;
  }
}
 

.main-panel {
  height: unset!important;
    max-height: unset!important;
    overflow-y: auto !important;
    min-height: 100%;
    padding-bottom: 40px;
  .header {
    margin-bottom: 0px;
  }
  .footer-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 999;
    background: #e4e4e4;
    .footer {
      padding: 0px;
      nav {
        padding: 5px 0px;
      }
    }
  }
}

.wrapper{
overflow-y: auto;
display:initial;
}

@media(max-width:540px){ 
  .main-panel {
    padding-bottom: 80px;
  }
}

.full-width {
  width: 100%;
}

.sidebar {
  // .layer {
  // 	background-color: rgba(17, 17, 19, 0.7);
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	width: 100%;
  // 	min-height: 100%;
  // 	}
  .logo-image {
    width: 28px !important;
    position: static !important;
  }
  .logo-title {
    font-size: 14px;
    margin-left: 8px;
  }
  .sidebar-wrapper {
    //background: #2d323e!important;
    //background-image: url('https://c.wallhere.com/photos/0f/f1/ocean_city_travel_sea_bw_white_mountain_holiday-874590.jpg!d');
    color: white;
    .user {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 136px;
      min-height: 136px;
      max-height: 136px;
      padding: 24px 0 64px 0;
      .username {
        margin: 0px;
      }
      .email {
        margin-top: 8px;
        margin-bottom: 0px;
      }
      .avatar-container {
        position: absolute;
        top: 92px;
        border-radius: 50%;
        padding: 8px;
        transform: translateX(-50%);
        left: 50%;
        .avatar {
          width: 72px;
          height: 72px;
          margin: 0;
        }
      }
    }
  }
  .nav {
    margin-top: 60px;
    .nav-item .nav-link {
      height: 40px;
      padding-left: 5px;
      padding-right: 10px;
    }
    i {
      font-size: 16px;
      color: white;
      margin-right: 5px;
      line-height: 23px;
    }
    p {
      font-size: 14px;
      color: white;
    }
    .group-title {
      padding-left: 20px;
      font-family: Muli, Helvetica Neue, Arial, sans-serif;
    }
  }
  .sidebar-background:after {
    background: #2d323e;
  }
  .logo:after {
    height: 0px !important;
  }
}

.sidebar[data-color="danger"] li.active {
  background-color: #039be5;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  border-radius: 0 20px 20px 0;
  margin-right: 16px;
  a {
    background-color: #039be5;
    box-shadow: none;
  }
}

// .sidebar[data-color="danger"] li.active {
// 	background-color: #888888;
// 	box-shadow: 0 4px 20px 0px rgba(37, 35, 35, 0.14), 0 7px 10px -5px rgba(126, 130, 131, 0.4);
// 	border-radius: 0 20px 20px 0;
// 	margin-right: 16px;
// 	a {
// 		background-color: #888888;
// 		box-shadow: none;
// 	}
// }
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: rgba(3, 155, 229, 0.1) !important;
}

.background-hlt {
  background-color: rgba(3, 155, 229, 0.1) !important;
  // color: #039be5 !important;
  font-weight: bold;
  border: 0px !important;
  margin-bottom: 0rem !important;
}

.user-mapping-title {
  font-weight: bold;
  padding-top: 5px;
  font-size: 1.1em;
  max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  // width: 70%;
}

.kyc-details {
}

.mat-form-field-hint-wrapper {
  flex-direction: row-reverse;
}
.mat-hint {
  color: #039be5 !important;
}

.is-bold{
  font-weight: bold;
}

.customer-list,
.tier-dashboard {
  @media screen and (max-width: 959px) {
    .header-bar {
      padding: 8px 0;
      // height: 140px !important;
      // min-height: 140px !important;
      // max-height: 140px !important;
    }
  }
}

.tier-dashboard {
  .add-product-button {
    a {
      margin-left: 10px;
    }
  }
  mat-accordion {
    .mat-expansion-panel {
      border: 1px solid #dcdbdb;
      border-radius: 6px;
      margin: 16px 0;
    }
    .mat-expansion-panel-body {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
    .table {
      margin-bottom: 0px;
    }
  }
}

.tier-user-mapping {
  .top-bg {
    height: 100px !important;
  }
  .content {
    padding-top: 20px;
  }
  .card {
    .card-header {
      h4 {
        font-size: 1.75rem;
        margin: 0px;
        font-family: Muli, Helvetica Neue, Arial, sans-serif;
      }
    }
    .mat-icon {
      font-size: 22px !important;
    }
    .cdk-drag,
    .cdk-drag-preview {
      padding: 10px;
    }
  }

  .board-list {
    width: 100%;
    padding-right: 24px !important;
    height: 100%;
  }

  .tier-list {
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .board-list .list {
    width: 100%;
    border-radius: 2px;
    -webkit-transition: box-shadow 150ms;
    transition: box-shadow 150ms ease;
  }

  .board-list .list .list-header {
    padding: 4px 8px;
    border-bottom: 1px solid;
    h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      font-family: muli;
    }
  }

  @media screen and (max-width: 959px) {
    .board-list {
      padding-bottom: 100px;
    }
    .board-list .list .list-header {
      height: 48px;
      min-height: 48px;
    }
  }

  .board-list .list .list-content {
    position: relative;
    min-height: 32px;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    mat-icon {
      font-size: 18px !important;
      vertical-align: text-bottom;
      margin-right: 5px;
    }
  }

  .tier-list .list .list-content {
    overflow-y: visible;
    max-height: initial;
  }

  .board-list .list .list-content .list-cards {
    position: relative;
    min-height: 32px;
    padding: 0 16px;
  }

  .board-list .list .list-footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    border-top: 1px solid;
    min-height: 48px;
  }

  .board-list .ngx-dnd-content {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .board-list.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  .board-list.gu-mirror > .list {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14),
      0 2px 16px 1px rgba(0, 0, 0, 0.12);
  }

  .board-list.gu-hide {
    display: none !important;
  }

  .board-list.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .board-list.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }
}

@media (max-width: 991px) {
  .sidemenu-toggle {
    display: none !important;
  }
  .filter-popup {
    position: static !important;
  }
}

.mat-sidenav {
  width: 200px;
}
