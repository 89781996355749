/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";
body { margin: 0; }

.mat-chip-remove {
    color:  #039be5 !important;
}

.mat-pseudo-checkbox-checked {
    background-color: #039be5 !important;
}

.mat-radio-label-content {
    color:#212529;
}

.mat-ink-bar{
    min-width: 80px!important;
}
 
.mat-tab-label{
    min-width: 80px!important; 
}

.mat-tab-body-content{
    height: unset!important;
    overflow: unset!important;
}

.mat-expansion-panel-header-description{
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mat-tab-label-active{
    background-color:#eee;
}

.mat-form-field-wrapper{
padding-bottom: 1em!important;
}

.price-tables .table thead th{
    line-height: 1;
    color: #555; 
    text-align: left;
    padding: 5px 10px;
    vertical-align: top;
    min-width: 80px;
    border-bottom: none; 
    white-space: nowrap;
    border-radius: 1px solid #aaa;
}

.price-tables .table td{
    font-size: 13px;
    font-weight: 700;
    height: 38px;
    padding: 8px 10px;
    vertical-align: bottom;
    text-align: left;
    border-left: 1px dashed #ccc;
    white-space: nowrap;
    border: none;
}
.price-tables .table td:first-child{
    border-left: none;
}

.price-tables .table tr.group-row:nth-child(2n+2) {
    border-bottom:3px solid #fff;
}

.price-tables .table tr {
    background-color: #f2f2f2;  
}
 

.price-tables .table tr td.fixed-table-col{
    background-color: #f2f2f2;  
}
.price-tables .table tr td.fixed-table-col{
    background-color: #f2f2f2;  
}
 
.price-tables .box-table{
    border: 0px solid #6496b4;
}

.price-tables .box-table thead{
    background-color: #fff;
}

.price-tables .price-table.style-1 .package-type{
    font-size: 14px!important;
} 

.table-wrapper{
    position: relative; 
    display: grid;
}

.fixed-table-col{
    position: absolute;
    left: 0;
    top: auto;
    width: 100px;  
}
 

@media(max-width:500px){
    .cdk-overlay-pane{
        width: 90%!important;
    }
}
 
 
@media(max-width:480px){
    .h1{
        font-size: 20px!important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rateCheck{
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    z-index: 9;
    height: 25px;
    width: 25px;
}